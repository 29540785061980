/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//noinspection ALL
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue-Regular.ttf");
}

/* Sweeet Alert */
// This is an example of variables we can change:
$swal2-font:  "BebasNeue";
$swal2-icon-size: 10em;

$swal2-title-color: #000;
$swal2-content-color: #000;

$swal2-actions-margin:0.375, -30px;
$swal2-button-margin: 1.25em auto 0 ;

$swal2-border-radius: 0;
// CONFIRM BUTTON
$swal2-confirm-button-order: null !default;
$swal2-confirm-button-border: 0;
$swal2-confirm-button-border-radius: 0;
$swal2-confirm-button-background-color: #FFD938 !default;
// $swal2-confirm-button-color: $swal2-white !default;
$swal2-confirm-button-font-size: 1.0625em !default;

$swal2-cancel-button-order: null !default;
$swal2-cancel-button-border: 0;
$swal2-cancel-button-border-radius: 0;
$swal2-cancel-button-background-color: #FFD938  !default;
$swal2-cancel-button-color: #000 !default;
$swal2-cancel-button-font-size: 1.0625em !default;

@import 'sweetalert2/src/sweetalert2';


.swal2-confirm, .swal2-cancel{
  width: 87px;
}

.swal2-container{
  height: 100vh;
  position: relative;
}
div.swal2-container.swal2-backdrop-show{
  background: rgba(0, 0, 0, 0.7);
}
div.swal2-popup.swal2-toast{
  background: #FFD938;
}
button.swal2-confirm{
  background-color: #FFD938 !important;
  color: #000 !important;
}

body{
  font-family: "BebasNeue";
  //margin-bottom: env(safe-area-inset-bottom);
  transform: none;
}
